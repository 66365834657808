import { GoBackProps } from ".";
import { useNavigate } from "react-router-dom";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import Button from "../Button";

export default function GoBack({ path, title }: GoBackProps) {
  let navigate = useNavigate();

  return (
    <div className="goback" style={{ maxWidth: "190px", paddingTop: "25px" }}>
      <Button
        color="secondary"
        variant="text"
        disableFocusRipple
        disableElevation
        disableRipple
        disableTouchRipple
        onClick={() => navigate(-1)}
        sx={{
          px: 0,
          ":hover": {
            color: "#52B46A",
            background: "transparent",
          },
        }}
      >
        <BsFillArrowLeftCircleFill size="24px" />
        <span>&nbsp; Back to {title}</span>
      </Button>
    </div>
  );
}
